var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    {
      attrs: { id: "verify-order-form" },
      on: { keypress: _vm.handleEnterTabbing },
    },
    [
      !_vm.connectedToProduction
        ? _c(
            "div",
            { staticClass: "verify-order-dev-info" },
            [
              _c("h6", { staticClass: "mt-0" }, [
                _vm._v("\n      Verify Order Form Dev Tools\n    "),
              ]),
              _c(
                "b-checkbox",
                {
                  model: {
                    value: _vm.editMode,
                    callback: function ($$v) {
                      _vm.editMode = $$v
                    },
                    expression: "editMode",
                  },
                },
                [_vm._v("\n      Edit Mode\n    ")]
              ),
              _vm._v(
                "\n    wrapperValid: " + _vm._s(_vm.wrapperValid) + "\n  "
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.loading
        ? _c("ct-centered-spinner", [
            _vm._v("\n    " + _vm._s(_vm.loadingText) + "\n  "),
          ])
        : _vm._e(),
      _vm.dataFullyLoaded
        ? _c(
            "b-card-body",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading",
                },
              ],
              staticClass: "pt-2",
            },
            [
              _c("schema-form", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.editMode,
                    expression: "editMode",
                  },
                ],
                key: `schema-form-${_vm.schemaFormKey}`,
                ref: `filing-form-${_vm.item.id}`,
                attrs: {
                  fields: _vm.form,
                  "contextual-jurisdiction": _vm.contextualJurisdiction,
                  "suggestion-fields": _vm.suggestionFields,
                  "hide-disclaimer": true,
                },
                on: {
                  input: function ($event) {
                    _vm.dirty = true
                  },
                  "ra-signup": _vm.raSignUp,
                  "show-contact-modal": _vm.showContactModal,
                  "suggestion-toggled": function ($event) {
                    return _vm.suggestionToggled($event)
                  },
                },
                model: {
                  value: _vm.formValues,
                  callback: function ($$v) {
                    _vm.formValues = $$v
                  },
                  expression: "formValues",
                },
              }),
              _c("schema-form-data-viewer", {
                attrs: {
                  "edit-mode": _vm.editMode,
                  "header-text": null,
                  "schema-viewer-data": _vm.dataViewerData,
                  "show-additional-official-data": false,
                  form: _vm.form,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("contact-modal", {
        ref: "contact-modal",
        attrs: { title: "Add a contact", type: "add" },
      }),
      _c(
        "b-modal",
        {
          ref: "cancel-form-edit-modal",
          attrs: {
            id: "cancel-form-edit-modal",
            title: "Cancel Changes",
            size: "md",
          },
          on: { cancel: _vm.cancelCancelled, ok: _vm.cancelConfirmed },
          scopedSlots: _vm._u([
            {
              key: "modal-cancel",
              fn: function () {
                return [_vm._v("\n      Continue editing\n    ")]
              },
              proxy: true,
            },
            {
              key: "modal-ok",
              fn: function () {
                return [_vm._v("\n      Cancel changes\n    ")]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(
            "\n    Are you sure you want to cancel? Any changes you've made will not be saved.\n    "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }